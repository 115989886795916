import React from "react"

import { graphql } from "gatsby"
import { Trans, useI18next } from "gatsby-plugin-react-i18next"
import { JesLayout } from "../components/jes-layout"
import { Bold, StandardParagraph } from "../components/blocks"

const ImpressumPage = ({ data }) => {
  const { t } = useI18next()

  return (
    <JesLayout title={t("Impressum")}>
      <h1>
        <Trans>Impressum</Trans>
      </h1>
      <StandardParagraph>
        <Bold>Angaben gem. § 5 TMG</Bold>
      </StandardParagraph>
      <StandardParagraph>
        JES Kulturstiftung
        <br />
        Adolf-Münzer-Straße 9<br />
        86919 Holzhausen / Ammersee
      </StandardParagraph>
      <StandardParagraph>Telefon: +49 (0)8806 924115</StandardParagraph>
      <StandardParagraph>
        E-mail:{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="mailto:post@jes-kulturstiftung.de"
        >
          post@jes-kulturstiftung.de
        </a>
      </StandardParagraph>
      <StandardParagraph>
        Internet:{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="http://www.jes-kulturstiftung.de"
        >
          www.jes-kulturstiftung.de
        </a>
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Vertretungsberechtigter Vorstand</Bold>
        <br /> Dr. Dr. Jochen Seifert und Erika Seifert
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Zuständige Aufsichtsbehörde</Bold>
        <br /> Regierung von Oberbayern
        <br />
        80534 München
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Steuerbehörde</Bold>
        <br />
        Finanzamt Kaufbeuren (St.-Nr. 125/147/01502)
      </StandardParagraph>
      <StandardParagraph>
        Rechtsfähigkeit von der Regierung von Oberbayern anerkannt am 17.12.2020
      </StandardParagraph>
      <StandardParagraph>
        Gemeinnützigkeit anerkannt am 18.02.2021
      </StandardParagraph>
      <StandardParagraph>
        <Bold>
          Verantwortlich für inhaltliche-redaktionelle Inhalte gem. § 18 Abs.2
          MStV
        </Bold>
        <br /> Dr. Dr. Jochen Seifert (Anschrift wie oben)
      </StandardParagraph>
      <StandardParagraph>
        © Alle Rechte liegen bei der JES Kulturstiftung
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Bildquellen</Bold>
        <ul style={{ fontSize: "inherit" }}>
          <li style={{ fontSize: "inherit" }}>Fundaziun Capauliana, Chur</li>
          <li style={{ fontSize: "inherit" }}>Manchester Art Gallery</li>
          <li style={{ fontSize: "inherit" }}>Museum Wiesbaden</li>
          <li style={{ fontSize: "inherit" }}>
            National Portrait Gallery, London
          </li>
          <li style={{ fontSize: "inherit" }}>Stadtmuseum München</li>
        </ul>
      </StandardParagraph>
    </JesLayout>
  )
}

export default ImpressumPage

export const query = graphql`
  query ImpressumPageQuery($language: String!) {
    markdownRemark(frontmatter: { meta: { eq: "impressum" } }) {
      html
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
